<template>
  <div class="row">
    <div class="col-12">
      <div class="row">
        <div class="col-md-12 col-12">
          <div class="position-relative">
            <div class="card shadow-none border-0 mb-0 rounded-0">
              <div class="card-body pb-0">
                <div class="row">
                  <div class="col-12 mb-2">
                    <div class="row justify-content-end">
                      <div class="col-12 col-md-4">
                        <v-select placeholder="Select Agent" v-model="agentId" :options="agentOpts" :reduce="val => val.value" :clearable="false" />
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <full-calendar ref="refCalendar" :options="calendarOptions" class="full-calendar" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="viewOrderInfoModal" v-model="showOrderInfo" :cancel-title="$t('Dismiss')" :no-close-on-backdrop="true"
      :title="$t('Order Details')" size="xl" @hidden="showOrderInfo = false">
      <OrderDetail :is-shop-view="true" :active-order-detail="activeOrderDetail" />
    </b-modal>
    <b-modal v-model="showLocaltionHistory" :cancel-title="$t('Dismiss')" :no-close-on-backdrop="true"
      :title="$t('Location Histories')" size="xl" @hidden="viewLocationHistoryClosed">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12 col-md-4 order-md-0 order-0">
              <b-list-group style="overflow-y: scroll; max-height: 95vh;">
                <b-list-group-item button v-for="(latLng, lk) in latLngs" :key="`${lk}`" @click="setMarker(latLng, lk)" :active="latLngActive === lk">{{ latLng.title }}</b-list-group-item>
              </b-list-group>
            </div>
            <div class="col-12 col-md-8 order-md-0 order-1">
              <div id="mapboxLeaflet" style="width: 100%; height: 95vh; border-radius: 10px;" />
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { isEmpty, dateTimeFormat, convertTZ } from '@/utilities'
import { mapActions, mapGetters } from 'vuex'
import { hideLoader, showLoader } from '@/@core/comp-functions/ui/app'
import OrderDetail from './shared/OrderDetail.vue'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import moment from 'moment'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import { useStoreUi } from './useStore'
let thisClass;

const minutesToHours = (minutes) => {
  if (isEmpty(minutes)) return "0:00";
  let mnt_str = "00";
  let hrs_str = "00";
  let remain_minutes;
  if (minutes > 0) {
    if (minutes > 59) {
      const a_hrs = minutes / 60;
      const hrs = Math.floor(a_hrs);

      remain_minutes = minutes - (hrs * 60);
      hrs_str = hrs;
      if (hrs_str < 10) {
        hrs_str = "0" + hrs;
      }
    } else {
      remain_minutes = minutes;
    }
    mnt_str = remain_minutes;
    if (remain_minutes < 10) {
      mnt_str = "0" + remain_minutes;
    }
  }
  return hrs_str + ":" + mnt_str;
}
export default {
  name: 'AgentAttendance',
  components: {
    OrderDetail,
    FullCalendar
  },

  props: {},

  data: () => ({
    timezone: 'UTC',
    showOrderInfo: false,
    activeOrderDetail: {},
    currentOrderId: null,
    calendarOptions: {
      plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
      initialView: 'dayGridMonth',
      headerToolbar: {
        start: 'sidebarToggle, prev,next, title',
        end: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
      },
      dragScroll: true,
      dayMaxEvents: 2,
      navLinks: true,
      eventClassNames({ event: calendarEvent }) {
        const calendarsColor = {
          AtStore: 'primary',
          AtCustomer: 'success',
        }
        // eslint-disable-next-line no-underscore-dangle
        const colorName = calendarsColor[calendarEvent._def.extendedProps.calendar]

        return [`bg-light-${colorName}`]
      },
      rerenderDelay: 350,
      events: [],
      // eslint-disable-next-line no-unused-vars
      eventClick(data, event, view) {
        thisClass.viewLoginHistory(data.event.extendedProps.payload)
      },
    },
    agentId: null,
    mapbox: null,
    latLngs: [],
    latLngActive: 0,
    showLocaltionHistory: false
  }),

  computed: {
    ...mapGetters('shopAgent', ['orders', 'agentsByOrderId', 'online_agents', 'offline_agents', 'available_agents', 'busy_agents', 'agentHours']),
    agentOpts: {
      get() {
        return this.agentHours.map(i => {
          return {
            value: i.id,
            label: `${i.first_name} ${i.last_name}`
          }
        })
      }
    },
    agentData: {
      get() {
        if (!isEmpty(this.agentId)) {
          return this.agentHours.find(i => i.id == this.agentId)
        }
        return null;
      }
    }
  },

  methods: {
    isEmpty, dateTimeFormat,
    ...mapActions('shopAgent', ['getTrackableOrders', 'getOrderTracking', 'getOrderDetails', 'getAgentsHours', 'getAgentsLocationHistory']),
    async openOrderDetails(order_id) {
      if (this.currentOrderId == order_id) {
        this.currentOrderId = null
        return
      }
      this.currentOrderId = order_id
    },
    viewLoginHistory(payload){
      showLoader()
      this.getAgentsLocationHistory(payload).then(r => {
        this.showLocaltionHistory = true
        this.latLngs = r
        if(!isEmpty(this.latLngs)){
          const latLng = this.latLngs.find((i, idx) => idx === 0)
          setTimeout(() => {
            this.setMarker(latLng, 0)
          }, 100);
        }
        hideLoader()
      }).catch(() => {
        hideLoader()
      })
    },
    setMarker(latLng, key) {
      this.latLngActive = key
      if (this.mapbox) {
        this.mapbox.remove()
      }
      this.mapbox = null
      if (!this.mapbox) {
        this.mapbox = window.L.map('mapboxLeaflet').setView([latLng.latitude, latLng.longitude], 6)
        const mapLink = '<a href="http://openstreetmap.org">OpenStreetMap</a>'
        window.L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          attribution: `Map data &copy; ${mapLink}`,
          maxZoom: 18,
        }).addTo(this.mapbox)
      }
      window.SuperApp.actions.addCustomMarker(latLng.latitude, latLng.longitude, {title: latLng.title}, this.mapbox)
      window.SuperApp.helper.resize()
    },
    viewLocationHistoryClosed(){
      this.showLocaltionHistory = false
      this.latLngActive = 0
      if (this.mapbox) {
        this.mapbox.remove()
      }
      this.mapbox = null
    }
  },

  mounted() {
    thisClass = this
    this.getAgentsHours().then(r => {
      if(!isEmpty(r)){
        this.timezone = r
      }
    })
  },

  watch: {
    agentData: {
      handler(nv) {
        let events = []
        if (!isEmpty(nv)) {
          events = nv.agent_hours.map(x => {
            const title = x.hours_txt
            const start = convertTZ(new Date(x.start), this.timezone)
            const end = convertTZ(new Date(x.end), this.timezone)
            return {
              title,
              start,
              end,
              payload: {
                date: dateTimeFormat(x.start, 'YYYY-MM-DD'),
                agent_id: nv.id
              }
            }
          })
        }
        this.calendarOptions.events = events
      },
      immediate: true
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/apps/calendar.scss';

.provider-booking-table {
  .vue-phone-number-input {
    width: 100%;
  }

  .booking-page-phone {
    margin-top: 10px;
  }
}
</style>

<style scoped>
.service-checkout-right-section {
  background: rgb(247, 247, 247);
  padding-left: 0;
}

.service-checkout-card {
  box-shadow: rgb(34 41 47 / 10%) 0px 4px 24px 0px !important;
  margin-bottom: 15px;
  margin-right: 5px;
  margin-left: 5px;
}

.service-checkout-card table.table th,
.service-checkout-card table.table td {
  padding: 2px 1rem;
}

@media screen and (min-width: 768px) {
  .col-md-5-5 {
    flex: 0 0 47%;
    max-width: 47%;
  }
}

@media only screen and (max-width: 767px) {
  .responsive-margin-bottom-checkout {
    margin-bottom: 10px;
  }

  .service-checkout-right-section {
    background: #fff;
    padding-left: 1rem;
  }

  .service-checkout-right-section .card-body {
    padding-left: 8px;
    padding-right: 8px;
  }
}
</style>
